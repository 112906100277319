import React, { useEffect, useState } from "react"
import CallingExtensions from "@hubspot/calling-extensions-sdk"
import { FaPhone } from "react-icons/fa"

import { Container } from "./style"
import api from "../../services/api"

const Hubspot = () => {
    const [numero, setNumero] = useState("")
    const [owner, setOwner] = useState("")

    const fazerChamada = async () => {
        await api.get(`/basix/makecall/hubspot/${owner}/${numero.replace(/[^\d{1}]/g, "")}`)
    }

    const carregarCallingExtension = async () => {
        const options = {
            // Whether to log various inbound/outbound messages to console
            debugMode: true,
            // eventHandlers handle inbound messages
            eventHandlers: {
                onReady: () => {
                    /* HubSpot is ready to receive messages. */
                    console.log("READY - onReady - 2")
                },
                onDialNumber: async (event) => {
                    console.log("onDialNumber")
                    const { phoneNumber, portalId } = event
                    setNumero(phoneNumber)
                    setOwner(portalId)

                    /* Dial a number */
                    console.log(event)
                },
                onEngagementCreated: (event) => {
                    /* HubSpot has created an engagement for this call. */
                    // console.log("onEngagementCreated")
                    // console.log(event)
                },
                onVisibilityChanged: (event) => {
                    /* Call widget's visibility is changed. */
                    // console.log("onVisibilityChanged")
                    // console.log(event)
                }
            }
        }

        const extensions = new CallingExtensions(options)

        console.log(extensions)
    }

    useEffect(() => {
        carregarCallingExtension()
    }, [])

    return (
        <Container>
            <p>{numero}</p>
            <button
                type="button"
                onClick={() => {
                    fazerChamada()
                }}
            >
                <FaPhone />
            </button>
            <span>Iniciar Chamada</span>
        </Container>
    )
}

export default Hubspot
