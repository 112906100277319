import React, { createContext, useState, useCallback } from 'react'
import { toast } from 'react-toastify'

import api from '../services/api'

export const AuthContext = createContext({
    username: null,
    email: null,
    nome: null,
    logout: null,
    signIn: null
  })

export const AuthProvider = ({children}) => {
    const [username, setUsername] = useState(() => {
        const user = localStorage.getItem('@CloudPanel:username')
    
        if(user){
          return user
        }
    
        return null
    })

    const [email, setEmail] = useState(() => {
        const mail = localStorage.getItem('@CloudPanel:mail')
    
        if(mail){
          return mail
        }
    
        return null
    })

    const [nome, setNome] = useState(() => {
        const nome = localStorage.getItem('@CloudPanel:nome')
    
        if(nome){
          return nome
        }
    
        return null
    })

    const signIn = useCallback(async ({user, domain, password}) => {
        try {
          const { data } = await api.post('/basix/centrex/auth', {
            user,
            domain,
            password
          })
    
          localStorage.setItem('@CloudPanel:username', data.username)
          localStorage.setItem('@CloudPanel:mail', data.email)
          localStorage.setItem('@CloudPanel:nome', data.nome)
    
          setUsername(data.username)
          setEmail(data.email)
          setNome(data.nome)

          toast.success('Login efetuado com sucesso !!!')
        } catch (error) {
          if(error.response){
            toast.error(error.response.data.error)
          }else{
            toast.error(error.message)
          }
        }
    }, [])

    const logout = useCallback(() => {
        localStorage.clear();
        document.location.reload();
    }, [])

    return (
        <AuthContext.Provider value={{ username, email, nome, logout, signIn }}>
          {children}
        </AuthContext.Provider>
      )
}