import styled from "styled-components"

export const Container = styled.div`
    width: 200px;
    height: 350px;
    background-color: #0098d8;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: white;
    }

    span {
        margin-top: 10px;
        color: white;
    }

    button {
        background-color: #36c836;
        font-size: 38px;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        border: 1px solid transparent;
        outline: none;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
            color: white;
        }
    }
`
