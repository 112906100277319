import React from "react"
import { Switch } from "react-router-dom"

import Route from "./Route"
import Hubspot from "../pages/Hubspot"
// import Dashboard from "../pages/Dashboard"
// import PromptMode from "../pages/PromptMode"
// import Programacoes from "../pages/Programacoes"
// import Programacoes_Add from "../pages/Programacoes_Add"
// import Programacoes_Edt from "../pages/Programacoes_Edt"
// import Feriados from "../pages/Feriados"
// import Feriados_Add from "../pages/Feriados_Add"
// import Feriados_Edt from "../pages/Feriados_Edt"

// import TimeclassDominios from "../pages/Timeclass/Dominios"
// import TimeclassGrupos from "../pages/Timeclass/Grupos"
// import TimeclassUsuarios from "../pages/Timeclass/Usuarios"
// import TimeclassHorarios from "../pages/Timeclass/Horarios"

const Routes = () => (
    <Switch>
        <Route path="/" exact component={Hubspot} />
        {/* <Route path="/dashboard" exact component={Dashboard} isPrivate />
        <Route path="/promptmode" exact component={PromptMode} isPrivate />
        <Route path="/promptmode/feriados/:id_domain/adicionar" exact component={Feriados_Add} isPrivate />
        <Route path="/promptmode/feriados/:id_domain" exact component={Feriados} isPrivate />
        <Route path="/promptmode/feriados/:id_domain/:id" exact component={Feriados_Edt} isPrivate />
        <Route path="/promptmode/:id_domain/adicionar" exact component={Programacoes_Add} isPrivate />
        <Route path="/promptmode/:id_domain" exact component={Programacoes} isPrivate />
        <Route path="/promptmode/:id_domain/:id" exact component={Programacoes_Edt} isPrivate />

        <Route path="/timeclass" exact component={TimeclassDominios} isPrivate />
        <Route path="/timeclass/:id_domain" exact component={TimeclassGrupos} isPrivate />
        <Route path="/timeclass/:id_domain/horarios/:id_group" exact component={TimeclassHorarios} isPrivate />
        <Route path="/timeclass/:id_domain/:id_group" exact component={TimeclassUsuarios} isPrivate /> */}
    </Switch>
)

export default Routes
